import React from 'react';
import { Link } from 'gatsby';
import {
    Button,
    Card,
    Container,
    Divider,
    Feed,
    Grid,
    Header,
    Icon,
    Image,
    Segment,
} from 'semantic-ui-react';
import Layout from '../components/layout';
import * as DefaultTemplate from '../components/LandingPages/DefaultTemplate';
import WhitePaperImage1 from '../images/whitepapers/home/debt-consolidation.png';
import WhitePaperImage2 from '../images/whitepapers/home/whats-causing-americans-stress-2017-1.png';
import WhitePaperImage3 from '../images/whitepapers/home/since-2007-cost-of-spending-has-grown-faster-than-income-2.png';
import WhitePaperImage4 from '../images/whitepapers/home/the-personal-debt-of-men-vs-women-3.png';
import WhitePaperImage5 from '../images/whitepapers/home/home-equity-is-on-the-rise-4.png';
import WhitePaperImage6 from '../images/whitepapers/home/what-contributes-to-credit-card-debt2017-5.png';
import WhitePaperImage7 from '../images/whitepapers/home/better-business-bureau-complaints-2016-6.png';
import EvenFinancialWidget from '../components/EvenFinancialWidget';


const featuredContent = [
    {
        slug: '/6-steps-out-of-debt/',
        image: 'bookkeeping.jpg',
        title: 'The Simple 6-Step Process To Getting Out of Debt',
    },
    {
        slug: '/stop-debt-collector-calls/',
        image: 'stop-debt-collector-calls.jpg',
        title: 'How To Permanently Stop Debt Collectors From Calling You',
    },
    {
        slug: '/understanding-debt-consolidation/',
        image: 'coin.jpg',
        title: 'Understanding Debt Consolidation: What It Does and How It Can Help',
    },
];

const moreResources = [
    {
        slug: '/how-to-get-out-of-debt/',
        image: 'how-to-get-out-of-debt.jpg',
        title: 'How to Get Out of Debt',
        excerpt: 'Learn more about the different kinds of debt, as well as how to tackle them. For even further reading, we’ve included an exhaustive list of the best resources for dealing with debt.',
    },
    {
        slug: '/debt-throughout-history/',
        image: 'debt-through-history.jpg',
        title: 'An Exploration of Debt Throughout History',
        excerpt: 'From ancient Rome to the Great Recession, debt and credit have been pivotal to human history. Learn the major takeaways on the history of debt so you know how to manage your own creditworthiness.',
    },
];

const relatedContent = [
    {
        slug: '/credit-counseling/family-budget-services-review/',
        image: 'fbsi-header.png',
        title: 'Family Budget Services Review',
    },
    {
        slug: '/debt-settlement/accredited-debt-relief-review/',
        image: 'accredited-debt-relief-review_2.png',
        title: 'Accredited Debt Relief Review',
    },
    {
        slug: '/credit-counseling/ecredit-advisor-review/',
        image: 'eCreditAdvisor_Home-buying.png',
        title: 'eCredit Advisor Review',
    },
    {
        slug: '/debt-settlement/debtmd/',
        image: 'debtmd-review.png',
        title: 'DebtMD Review',
    },
    {
        slug: '/debt-settlement/freedom-debt-relief/',
        image: 'freedom-debt-relief-review.jpg',
        title: 'Freedom Debt Relief Review',
    },
];

const mainArticle = (
    <>
        <Divider hidden className="half-size" />
        <Header as="h3">Debt Consolidation</Header>
        <p><Image src={WhitePaperImage1} alt="Debt Consolidation" /></p>
        <p>Many people find themselves struggling with multiple forms of debt.</p>
        <p>
            It&rsquo;s easy enough to get credit. Paying it all back
            {' '}
            <Link to="/credit-counseling/">can be another story</Link>
            .
        </p>
        <p>Debt doesn&rsquo;t just sit still and behave itself. It grows if you don&rsquo;t control it.</p>
        <p>If you&rsquo;re just keeping up with minimum payments, you&rsquo;ll notice your debt start to swell bigger rather than shrink.</p>
        <p>You could be looking at years of treading water and getting nowhere if you don&rsquo;t do something about it.</p>
        <p>The average household in America with credit card debt owes on average, $15,654.</p>
        <p>The average number of credit cards among American credit card owners is 3.7 cards.</p>
        <p>Does a $15,000 debt stretched out across 3+ credit cards sound familiar to you and your life?</p>
        <p>
            The bad news is all the debt you&rsquo;re carrying is
            {' '}
            <a href="https://www.cambridge-credit.org/financial-stress-and-your-health.html">bad for your mental and physical health</a>
            .
        </p>
        <p>The good news is there are options to help you reverse the growth of your debt and start shrinking it down to nothing.</p>
        <p>
            Debt consolidation
            {' '}
            <a
                href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=homepage&sub2=solutions"
                target="_blank"
                rel="noopener noreferrer"
            >
                solutions
            </a>
            {' '}
            roll all your debts together, so you don&rsquo;t have to juggle multiple payments.
        </p>
        <p>Ideally, you&rsquo;ll save money on the annual interest you pay with a lower interest rate.</p>
        <p>You can also get your debt paid off more quickly if you choose a structured repayment schedule.</p>
        <p>If you&rsquo;ve tried unsuccessfully to get control of your debt in the past, you should check out the debt consolidation options out there.</p>
        <p>Look at the tips below and pick the ones that work best for your debt consolidation needs.</p>
        <p><Image src={WhitePaperImage2} alt="What's Causing Americans Stress? (2017)" /></p>

        <Divider hidden className="half-size" />
        <Header as="h3">
            When Debt Consolidation Works Best
            <Header.Subheader>Go with debt consolidation if your debt isn&rsquo;t enormous and your credit score is good</Header.Subheader>
        </Header>

        <p>Debt consolidation isn&rsquo;t for every person or debt scenario.</p>
        <p>
            <strong>Debt consolidation could be a good idea or a bad one.</strong>
            {' '}
            Some situations are perfect for solving via debt consolidation. In other situations, debt consolidation is not recommended.
        </p>
        <p>
            <strong>How&rsquo;s your credit score?</strong>
            {' '}
            Debt consolidation is a good option if you still have a credit score high enough for you to get approved for credit cards and loans.
        </p>
        <p>
            <strong>Are you in debt up to your ears or just to your waist?</strong>
            {' '}
            Ideally, consolidation is used for a debt less than 50% of a person&rsquo;s annual income, known as your debt-to-income ratio.
        </p>
        <Header as="h3">Do the math to figure out if you are right for debt consolidation</Header>
        <p>Take the time to run a little self-assessment to see if you are a good candidate for the debt consolidation solutions we&rsquo;re about to tell you about.</p>
        <p>
            <strong>Total up your debts.</strong>
            {' '}
            Add up your various forms of unsecured debt and the interest rates being charged for each one.
        </p>
        <p>
            Include
            {' '}
            <a
                href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&sub1=homepage&sub2=credit-cards"
                target="_blank"
                rel="noopener noreferrer"
            >
                credit cards
            </a>
            {' '}
            and
            {' '}
            <a
                href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=homepage&sub2=personal-loans"
                target="_blank"
                rel="noopener noreferrer"
            >
                personal loans
            </a>
            {' '}
            (don&rsquo;t include mortgages or auto loans since those are secured by your home/car).
        </p>
        <p>If your debt-to-income ratio shows your total unsecured debt is less than 50% of your annual income you&rsquo;re in the zone for debt consolidation.</p>
        <p>
            <strong>Get your credit score.</strong>
            {' '}
            You can get a free copy of your credit report from each of the three bureaus (Equifax, TransUnion, and Experian) once a year.
        </p>
        <p>You can also get your credit score for free through many online and financial institution lenders.</p>
        <p>
            <strong>You better be &ldquo;Good.&rdquo;</strong>
            {' '}
            A &ldquo;Good&rdquo; credit score is over 690. If your debt consolidation solutions involve a new loan or credit card, you&rsquo;ll want your FICO credit score to be at least 690.
        </p>
        <p>Having a good score means you qualify for loans and credit cards with lower interest rates and APRs.</p>
        <p>
            <strong>Move high interest to low interest.</strong>
            {' '}
            The whole point of debt consolidation is to roll high-interest balances into a lower-interest credit account.
        </p>
        <p>
            <strong>Here&rsquo;s an ideal scenario.</strong>
            {' '}
            Let&rsquo;s say you&rsquo;ve got $15,000 in credit card debts across three different cards. One card charges 18.99% APR. Another charges 16.24%. The third is charging 15% APR.
        </p>
        <p>You&rsquo;ve always managed to keep on top of at least the minimum payments. When you check your credit score, you&rsquo;re pleased to see it&rsquo;s a respectable 710 or &ldquo;Good.&ldquo;</p>
        <p>The annual salary you bring home is $55,000 a year. Therefore, your total debt is only 27.27% of your annual income.</p>
        <p>What you have is a perfect scenario for debt consolidation.</p>
        <p><Image src={WhitePaperImage3} alt="Since 2007 Cost of Spending has Grown Faster than Income" /></p>
        <Header as="h3">Do NOT go with debt consolidation if your debt is too big or too small</Header>
        <p>If your debt is small enough to eliminate it within six months to a year, don&rsquo;t bother looking for a debt consolidation solution.</p>
        <p>You won&rsquo;t save enough to make it worthwhile.</p>
        <p>
            <strong>Are you in way over your head?</strong>
            {' '}
            Does your debt-to-income ratio reveal your debt total is more than 50% of your income?
        </p>
        <p>
            You&rsquo;d be better off looking at other debt relief solutions like
            {' '}
            <Link to="/debt-settlement/">debt settlement</Link>
            {' '}
            companies.
        </p>
        <p>Companies providing those services can renegotiate with your lenders and shrink your total debt. You pay an affordable monthly installment, and then the company pays off your creditors with a lump sum.</p>

        <Divider hidden className="half-size" />
        <Header as="h3">
            About Debt Consolidation Loans
            <Header.Subheader>Roll your debt up into one personal debt consolidation loan and with a simple payment schedule</Header.Subheader>
        </Header>
        <p>The first option you have for consolidating all your debt together is an unsecured personal loan.</p>
        <p>A fixed-rate personal loan can be used to pay off all your existing debt at once.</p>
        <p>Then you set up a repayment plan with a single monthly payment over a set term.</p>
        <p>
            <strong>One payment for easier debt management.</strong>
            {' '}
            It&rsquo;s much easier to have one loan payment than it is to stay on top of multiple monthly credit card minimums.
        </p>
        <p>The interest rate should be less than the average rate you were paying on your existing debt.</p>
        <Header as="h3">Get a debt consolidation personal loan online or through a bank</Header>
        <EvenFinancialWidget
            url="https://embed.hifiona.com/ui/multi-product/index.html?partner=debtconsolidationembed&access_token=628217c7-f4c2-4006-a2b1-3031b4c21272_8362ee6d-9497-4440-aaf2-8c6e5685f90b&company_uuid=65d1a0bd-1cab-43c4-b755-2a6bff913a03&productType=loan&pw_fiona=true&available_products=loan"
            slug="homepage"
        />
        <p>A lot of banks don&rsquo;t give unsecured personal loans anymore. A few will still give you an unsecured loan, including Wells Fargo and Citibank.</p>
        <p>
            <strong>Online lenders can get you the unsecured loan you need.</strong>
            {' '}
            You can also look for online lenders to get a
            {' '}
            <a
                href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=homepage&sub2=debt-consolidation-loan"
                target="_blank"
                rel="noopener noreferrer"
            >
                debt consolidation loan
            </a>
            . Examples of online, peer-to-peer lending companies providing debt consolidation loans include SoFi and LendingTree.
        </p>
        <p>
            <strong>Figure out what you owe to get started.</strong>
            {' '}
            Add up all your debts, including credit cards, medical bills, utility bills, and payday loans. Then apply for a debt consolidation loan for the total amount to pay them all off.
        </p>
        <p>
            <strong>Shop around and compare.</strong>
            {' '}
            When you&rsquo;re shopping around for a good debt consolidation loan try to get one with a better interest rate than the average of your existing debts.
        </p>
        <p>Calculate the monthly payments you&rsquo;ve been making on your existing debts and negotiate a new single monthly payment that is more manageable.</p>
        <p>
            <strong>Lower monthly payments, lower interest, and easy management.</strong>
            {' '}
            Unsecured personal loans can save you money and make your
            {' '}
            <a
                href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&sub1=homepage&sub2=life-easier"
                target="_blank"
                rel="noopener noreferrer"
            >
                life easier
            </a>
            .
        </p>
        <p>Let&rsquo;s say you have one credit card balance for $5,000 at 18.9%, and another for $3,000 at 17.99%. Combined, the minimum payments for both loans are $320.</p>
        <p>
            You also have a
            {' '}
            <a
                href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&sub1=homepage&sub2=student-loan"
                target="_blank"
                rel="noopener noreferrer"
            >
                student loan
            </a>
            {' '}
            with $5,000 left to repay over three years at 4% interest. Your monthly payment is $147.62.
        </p>
        <p>Your total debt is $13,000, your average APR is 12.99%, and your total monthly payment is $467.62.</p>
        <p>When you got to negotiate your debt consolidation personal loan, these are the numbers you are trying to beat.</p>
        <p>You could apply for a five-year loan for $13,000 at an APR of 9%.</p>
        <p>
            <strong>Monthly payment becomes more reasonable.</strong>
            {' '}
            Your monthly payment goes down to $269.86—almost $200 less than you were paying before just to stay on top of the credit card minimum payments.
        </p>
        <p>
            <strong>You know exactly when you&rsquo;ll be debt-free.</strong>
            {' '}
            One of the best things about a debt consolidation personal loan is the fixed repayment term.
        </p>
        <p>Rather than being unsure if you&rsquo;re ever going to be able to wipe out your debt, with the loan above, you know you will be debt-free five years down the road.</p>
        <p><Image src={WhitePaperImage4} alt="The Personal Debt of Men vs. Women" /></p>

        <Divider hidden className="half-size" />
        <Header as="h3">
            Home Equity Loans
            <Header.Subheader>Harness the value of your home to erase your other debt</Header.Subheader>
        </Header>
        <p>Another form of loan for debt consolidation is a home equity loan.</p>
        <p>If you are a homeowner who&rsquo;s been faithfully making mortgage payments, you&rsquo;ve probably built up some equity in your home.</p>
        <p>
            <strong>Re-mortgage your home to pay all your debts.</strong>
            {' '}
            A home equity loan, otherwise known as a second mortgage, lets you borrow off the money you&rsquo;ve already put into your home.
        </p>
        <p>Like the unsecured personal loan, you get your home equity loan and use it to pay off all your debts.</p>
        <p>
            <strong>Smaller payments at lower interest are win-win.</strong>
            {' '}
            Then you set up a repayment schedule and pay it off in monthly installments at a lower interest rate than you were paying before.
        </p>
        <p>Since a home equity loan is an insured loan (your home is the collateral) the interest rates will be much less than credit cards or even unsecured personal loans. A typical home equity loan APR is 4%.</p>
        <Header as="h3">Save a lot of money in interest by taking out a home equity loan</Header>
        <p>It&rsquo;s a good idea to pick a variable interest rate for your home equity loan as it could mean your interest rate could drop even lower than 4%.</p>
        <p>
            <strong>Take out a line of credit on your home.</strong>
            {' '}
            Another type of credit where you borrow from the value of your home is a Home Equity Line of Credit (HELOC).
        </p>
        <p>It&rsquo;s much the same as a home equity loan except it is a revolving line of credit with no fixed repayment schedule.</p>
        <p>You can also continue to dip back into it over time if you need the funds.</p>
        <p>It&rsquo;s important to be aware of the pitfalls of a HELOC. After an initial &ldquo;draw&rdquo; period (5-10 years), the line of credit becomes a home equity loan with a fixed repayment schedule. If the market changes over 5–10 years, your interest rate might be higher.</p>
        <p>For both HELOCs and home equity loans, your home is the security. If you can&rsquo;t keep up with the payments, you could have your house foreclosed on.</p>
        <p>
            <strong>Home equity loans have the lowest possible interest rates.</strong>
            {' '}
            In either case, negotiate an interest rate much lower than what you&rsquo;re paying on your credit cards and other debts.
        </p>
        <p>With the home equity loan, you can set up a long repayment schedule.</p>
        <p>For example, it&rsquo;s not uncommon for a home equity loan to have a repayment period of 30 years.</p>
        <p>We can take the example we looked at for the unsecured personal loan to illustrate how a home equity loan can work for debt consolidation.</p>
        <p>The hypothetical scenario involved $13,000 in combined credit card and student debt, with an average APR of 12.99%. The monthly payment just to stay on top of the credit card minimums was $467.62.</p>
        <p>By taking out a second mortgage on your home of $13,000 you can pay the other three debts off immediately.</p>
        <p>
            <strong>Tiny monthly payments over 20 years.</strong>
            {' '}
            Then you can give yourself 20 years to pay back the $13,000 at a variable interest rate around 4%.
        </p>
        <p>At that rate, your monthly payments are a mere $78.78. You&rsquo;d probably hardly notice the money coming out of the bank.</p>
        <p>Even with the low-interest rate, you&rsquo;ll end up paying $5,906.59 in interest over the 20-year loan term.</p>
        <p>
            <strong>Don&rsquo;t like paying interest? Shorten the payback.</strong>
            {' '}
            If you&rsquo;re not comfortable with paying all that interest, you can negotiate a five-year home equity loan term.
        </p>
        <p>Your monthly payment will be $239.41 (which is still much lower than you were paying before just to make monthly minimums). The interest you&rsquo;ll pay over five years will only be $1,364.89.</p>
        <p><Image src={WhitePaperImage5} alt="Home Equity is on the Rise" /></p>
        <Divider hidden className="half-size" />
        <Header as="h3">
            Balance Transfers May Help
            <Header.Subheader>Use credit cards that offer 0% APR introductory for a year or more to pay off your old credit card debts</Header.Subheader>
        </Header>
        <p>
            If the debt you are dealing with
            {' '}
            <Link to="/credit-card-debt/">involves multiple maxed-out, high-interest credit cards</Link>
            , your best bet for debt consolidation could be a balance transfer credit card.
        </p>
        <p>
            You basically combine those other credit card debts and move them over to a
            {' '}
            <a
                href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&sub1=homepage&sub2=new-credit-card"
                target="_blank"
                rel="noopener noreferrer"
            >
                new credit card
            </a>
            .
        </p>
        <Header as="h3">Find a good balance transfer credit card to move your high- interest credit card debt</Header>
        <p>
            <strong>The longer the 0% APR period the better.</strong>
            {' '}
            A good
            {' '}
            balance transfer credit card
            {' '}
            will have an introductory offer where you&rsquo;re charged 0% APR for 12–21 months.
        </p>
        <p>You get at least a year to pay off your existing credit card balances without being charged interest.</p>
        <p>
            <strong>Pay a lower APR if you don&rsquo;t pay it all off immediately.</strong>
            {' '}
            If you&rsquo;re unable to pay off your existing credit card debt within the 0% intro period, you&rsquo;ll get charged the regular credit card APR. Ideally, it will be lower than the APR you were paying on the other cards.
        </p>
        <p>Good balance transfer credit cards have reasonable regular APRs (especially if you have a &ldquo;Good&rdquo; or better credit score).</p>
        <p>Keep an eye on the APR for regular purchases when you sign up. The 0% APR period will someday end. You could see yourself paying 20% or more on new purchases, including purchases made during the intro period.</p>
        <p>
            <strong>Balance transfer fees are something to watch closely.</strong>
            {' '}
            One more thing to pay attention to when choosing a balance transfer credit card is the balance transfer fee.
        </p>
        <p>Most credit cards charge a fee for moving a balance from another card.</p>
        <p>A reasonable balance transfer fee is 3%. Some cards charge 5%, which means they aren&rsquo;t ideal for balance transfers.</p>
        <p>The best balance transfer credit cards have an intro period with no balance transfer fee and 0% APR. For example, the Chase Slate Visa offers 0% APR and $0 balance transfer fees for 15 months.</p>
        <p>
            <strong>The numbers don&rsquo;t lie – balance transfers work.</strong>
            {' '}
            We can crunch some numbers to show you how a balance transfer credit card can work for debt consolidation.
        </p>
        <p>Let&rsquo;s say you&rsquo;ve got three maxed-out credit cards. One owes $4,000 and charges 18.99%. The second has an unpaid balance of $5,000, with an APR of 17.99%. The third card is $3,000 in debt, at 15.99%.</p>
        <p>All the debt can be consolidated under one balance transfer credit card with an intro 0% APR period of one year.</p>
        <p>If the balance transfer fee is 3% you&rsquo;ll be paying $360 to consolidate your credit card debts.</p>
        <p>
            <a
                href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&sub1=homepage&sub2=youll-be-saving"
                target="_blank"
                rel="noopener noreferrer"
            >
                You&rsquo;ll be saving
            </a>
            {' '}
            a year&rsquo;s worth of interest charged at a high rate.
        </p>
        <p>
            <strong>Rates don&rsquo;t disappear.</strong>
            {' '}
            If you are still carrying a balance after the 0% introductory offer, you could be hit with a super-high APR, which you don&rsquo;t want.
        </p>
        <p>
            <strong>There is a different rate for charges besides the amount you transferred.</strong>
            {' '}
            Yes, you&rsquo;ll pay 0% for the amount you transferred, according to the card, but you may pay a high rate (depending on the card and your credit score) for any new charges you put on the card.
        </p>
        <p>So, if you put new charges on your balance transfer card, you won&rsquo;t be getting that 0% interest-free rate.</p>
        <p><Image src={WhitePaperImage6} alt="What Contributes to Credit Card Debt?(2017)" /></p>
        <Divider hidden className="half-size" />
        <Header as="h3">
            Debt Settlement Companies
            <Header.Subheader>For a hefty fee, these companies can negotiate down your total debt</Header.Subheader>
        </Header>
        <p>Home equity loans, unsecured personal loans, and balance transfer credit cards are good tools for paying off a moderate-sized debt more efficiently.</p>
        <p>Your debt level might be so high, these options are not viable.</p>
        <p>
            <strong>Last-ditch solution.</strong>
            {' '}
            Before declaring bankruptcy, it&rsquo;s a good idea to talk to a debt settlement company. These are often non-profit debt consolidation services.
        </p>
        <p>Sometimes these agencies are referred to as a &ldquo;debt consolidation company&rdquo; and their services can include debt consolidation programs and debt management programs.</p>
        <p>Like the previous solutions, these agencies also consolidate all your debt within a single umbrella.</p>
        <p>
            <strong>Shrink the debt or lose it all.</strong>
            {' '}
            The difference is, the companies contact your creditors and negotiate for a smaller payment than what&rsquo;s owed.
        </p>
        <p>For the creditors, settling for a smaller amount through debt forgiveness is better than getting nothing, which is exactly what they&rsquo;d get if a bankruptcy happened.</p>
        <Header as="h3">Work with a debt settlement company to set up a payment plan on your reduced debt</Header>
        <p>You pay the debt settlement company an affordable monthly payment. When you&rsquo;ve submitted enough money to pay off the lowered loan amount, the company sends it to the creditor.</p>
        <p>
            <strong>The company gets its share.</strong>
            {' '}
            The debt settlement agency will also take a cut of the savings it negotiated for you.
        </p>
        <p>Say you have $50,000 in combined debt with personal lenders, credit card debts, and auto loans.</p>
        <p>Cut the total debt in half. The debt settlement agency will contact all your creditors. It might talk them down to a total debt of $25,000.</p>
        <p>You&rsquo;ll pay the debt settlement company a monthly installment to pay down the debt over an agreed upon period.</p>
        <p>Every time your installments total enough to pay off one of your debts, the company will submit the payment.</p>
        <p>
            <strong>Be prepared to pay the fee.</strong>
            {' '}
            In our $50,000 example, you will have to pay more than the renegotiated total of $25,000, since the debt settlement company needs its cut of the $25,000 in debt forgiveness it just saved you.
        </p>
        <p>The fee you&rsquo;ll get charged depends on how much money the company saved you.</p>
        <p>
            <strong>Your credit score is going to take a beating.</strong>
            {' '}
            Although a debt settlement company is going to reduce your debt, your credit score is going to be battered since full payments were never received by your creditors.
        </p>
        <p>
            <strong>Watch out for sketchy companies.</strong>
            {' '}
            The internet can connect you to legit debt settlement companies. It could also lead you to some unethical companies set up to take advantage of people with out-of-control debt.
        </p>
        <p>
            The best place to start when you&rsquo;re checking to see if a debt settlement company is reputable is the
            {' '}
            <a href="https://www.bbb.org/">Better Business Bureau website</a>
            .
        </p>
        <p><Image src={WhitePaperImage7} alt="Better Business Bureau Complaints (2016)" /></p>
        <Header as="h3">Consolidate your debts and stop juggling just to keep your head above water</Header>
        <p>If you&rsquo;re like the average American credit card customer, you&rsquo;ve got $15,000 in debt spread out across more than three credit card accounts.</p>
        <p>You can keep up with the monthly minimums and hope someday you&rsquo;ll get the debt paid off.</p>
        <p>Or you can pick one of the options we&rsquo;ve offered above and consolidate your debt.</p>
        <p>You&rsquo;ll immediately enjoy the ease of managing a single payment rather than trying to stay on top of multiple due dates.</p>
        <p>Fixed payment plans, lower interest rates, and introductory periods with 0% APR are all features of debt consolidation solutions you can benefit from.</p>
        <p>Keep in mind your credit behavior got you into your current financial situation.</p>
        <p>It&rsquo;s a good idea to consider contacting a credit counseling agency and seeing a credit counselor. They can help you put together a debt management plan.</p>
    </>
);


const IndexPage = () => (
    <Layout>
        <DefaultTemplate.Wrapper>
            <DefaultTemplate.Hero>
                <>
                    <Segment attached>
                        <Container fluid className="hero hero__home" textAlign="center">
                            <Container text>
                                <Header as="h1" size="large">Debt Consolidation</Header>
                                <Header as="h2" size="medium">Resources, tools, and programs to help you manage your debt</Header>
                            </Container>
                            <Container fluid className="hero--button-group">
                                <Button primary size="huge" href="/loans/">
                                    Debt Consolidation
                                </Button>
                                <Button primary size="huge" href="/debt-settlement/">
                                    Debt Settlement
                                </Button>
                                <Button primary size="huge" href="/credit-counseling/">
                                    Credit Counseling
                                </Button>
                            </Container>
                        </Container>
                    </Segment>
                </>
            </DefaultTemplate.Hero>
            <DefaultTemplate.Featured>
                <Container textAlign="center">
                    <Divider hidden />
                    <Header size="large" textAlign="center">
                        Get Out of Debt
                    </Header>
                    <Grid>
                        <Grid.Row columns={1} centered>
                            <Grid.Column tablet={16} computer={14}>
                                <Grid stackable>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <Link to="/loans/">
                                                <Segment attached textAlign="center">
                                                    <Header size="small" icon color="blue" className="no-margin">
                                                        <Icon name="money bill alternate outline" color="blue" />
                                                        Debt Consolidation Loans
                                                    </Header>
                                                </Segment>
                                                <Segment attached textAlign="center" secondary>
                                                    <small>Consolidate multiple loans into one monthly&nbsp;payment</small>
                                                </Segment>
                                            </Link>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Link to="/debt-settlement/">
                                                <Segment attached textAlign="center">
                                                    <Header size="small" icon color="blue" className="no-margin">
                                                        <Icon name="calendar times outline" color="blue" />
                                                        Debt Settlement
                                                    </Header>
                                                </Segment>
                                                <Segment attached textAlign="center" secondary>
                                                    <small>Take steps to negotiate your repayment&nbsp;terms</small>
                                                </Segment>
                                            </Link>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Link to="/credit-counseling/">
                                                <Segment attached textAlign="center">
                                                    <Header size="small" icon color="blue" className="no-margin">
                                                        <Icon name="comments outline" color="blue" />
                                                        Credit Counseling
                                                    </Header>
                                                </Segment>
                                                <Segment attached textAlign="center" secondary>
                                                    <small>Speak to qualified credit experts, learn debt reduction strategies, and more</small>
                                                </Segment>
                                            </Link>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Header size="large" textAlign="center">
                        Learn About Debt
                    </Header>
                    <div className="content--featured">
                        <Feed>
                            {featuredContent.map((item, idx) => (
                                <Feed.Event
                                    key={idx.toString() + item.slug}
                                    as="a"
                                    href={item.slug}
                                    image={`/media/resize/200x150/${item.image}`}
                                    content={item.title}
                                />
                            ))}

                        </Feed>
                    </div>
                    <div className="content--featured__desktop">
                        <Card.Group stackable itemsPerRow={3}>
                            {featuredContent.map((item, idx) => (
                                <Card key={idx.toString() + item.slug} as={Link} to={item.slug}>
                                    <Image src={`/media/resize/400x300/${item.image}`} wrapped ui={false} />
                                    <Card.Content>
                                        <Card.Header>
                                            {item.title}
                                        </Card.Header>
                                    </Card.Content>
                                </Card>
                            ))}
                        </Card.Group>
                    </div>
                </Container>
            </DefaultTemplate.Featured>
            <DefaultTemplate.Body>
                {mainArticle}
                <Header size="large">More Debt Resources</Header>
                <Card.Group stackable itemsPerRow={2}>
                    {moreResources.map((item, idx) => (
                        <Card key={item.slug + idx.toString()} as={Link} to={item.slug}>
                            <Image src={`/media/resize/400x300/${item.image}`} wrapped ui={false} />
                            <Card.Content>
                                <Card.Header>
                                    {item.title}
                                </Card.Header>
                                <Card.Description>{item.excerpt}</Card.Description>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
                <Divider hidden />
            </DefaultTemplate.Body>
            <DefaultTemplate.PostSidebar>
                <Header size="medium">Related Content</Header>
                <Feed>
                    {relatedContent.map((item, idx) => (
                        <Feed.Event
                            key={item.slug + idx.toString()}
                            as="a"
                            href={item.slug}
                            image={`/media/resize/200x150/${item.image}`}
                            content={item.title}
                        />
                    ))}
                </Feed>
            </DefaultTemplate.PostSidebar>
        </DefaultTemplate.Wrapper>
    </Layout>
);

export default IndexPage;
